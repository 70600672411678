function Approach({bayan_approach}) {
    const DEFAULT_HEADING = "The Bayan Approach";
    const DEFAULT_TEXT = "Bayan defines the Islamic tradition as the body of knowledge and methodology, both oral and written, initiated by the Prophet Muhammad ﷺ with his Companions (al-ṣaḥābah), may God be well pleased with them, and then developed by them, their followers (al-tabiʿīn), and the successive generations of Islamic scholars from that time to the present, as a means for preserving, transmitting, and applying the teachings of God’s final revelation from generation to generation.";
    const DEFAULT_CTA_TEXT = "Learn More";
    const DEFAULT_CTA_LINK = "/about/philosophy";

    return (
        <div className="bayan-approach">
			<div className="container">
				<h1 className="text-center heading" alt="Bayan On-Demand">{bayan_approach?.heading ?? DEFAULT_HEADING}</h1>
                <br/>
				<p className="body-text" dangerouslySetInnerHTML={{__html:(bayan_approach?.text ?? DEFAULT_TEXT)}}/>
                <br/>
                <div className="btn-container">
                    <a href={bayan_approach?.cta_link ?? DEFAULT_CTA_LINK} target="_blank">{bayan_approach?.cta_text ?? DEFAULT_CTA_TEXT}</a>
                </div>
            </div>
        </div>
    );
}

export default Approach;