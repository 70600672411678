// import "./style.scss";
import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { getHeaderComponentData } from "../../../api/header";
import { shallowEqual, useSelector } from "react-redux";
import RequestInfoModal from "../../common/requestInfoModal";
import { getStudentBodyProfiles } from "../../../api/student_body_profiles";
import { socialLinks } from "../../../constants"
// import Image from 'next/image';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

function Header(props) {
  // const headerComponentData = props?.headerComponentData?.length ? props?.headerComponentData[0] : null;
  // console.log("headerComponentData",headerComponentData,props?.headerComponentData);
  const [clickedOutside, setClickedOutside] = useState(false);
  const node = useRef();
  const [loginModel, setLoginModel] = useState(false);
  const [dropDown, setDropDown] = useState({
    programs: "",
    admissions: "",
    community: "",
    aboutByana: "",
    closeAll: "",
  });
  const [dropDownOpen, setDropDownOpen] = useState({
    programs: "",
    admissions: "",
    community: "",
    aboutByana: "",
    closeAll: "",
  });
  const { primaryColor, secondaryColor, bgColor } = props.theme.bayanTheme;
  const [href, setHref] = useState("");
  const [pageData, setPageData] = useState("");
  const [openRequestInfoModal, setOpenRequestInfoModal] = useState(false);
  const [studentBodyProfiles, setStudentBodyProfiles] = useState([]);
  const { userId } = useSelector(
    (state) => ({
      userId: state.userAuth.userId,
    }),
    shallowEqual
  );

  useEffect(() => {
    getStudentBodyProfiles().then((res) => {
      setStudentBodyProfiles(res[0]?.student_body_profile);
    });
  }, []);

  useEffect(() => {
    getHeaderComponentData().then((res) => {
      if (res) {
        setPageData(res);
      }
    });
    document.addEventListener("mousedown", handleClick);
    setHref(window.location.href);
    // let headerData = getHeaderComponentData();
    // console.log("sadad", headerData);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node?.current?.contains(e.target)) {
    } else {
      toggleNavs("closeAll");
    }
  };

  const toggleNavs = (selector) => {
    // checking if selected component is empty then open it otherwise close it.
    if (dropDown[selector] === "") {
      // Resetting all states....
      for (const key in dropDown) {
        dropDown[key] = "";
      }
      for (const key in dropDownOpen) {
        dropDownOpen[key] = "";
      }
      // Resetting all states in case close all event triggers else show and toggle arrow....
      if (selector === "closeAll") {
        setDropDown({ ...dropDown });
        setDropDownOpen({ ...dropDownOpen });
      } else {
        setDropDown({ ...dropDown, [selector]: "w3-show" });
        setDropDownOpen({ ...dropDownOpen, [selector]: "open" });
      }
    } else {
      setDropDown({ ...dropDown, [selector]: "" });
      setDropDownOpen({ ...dropDownOpen, [selector]: "" });
    }
  };
  const handleRequestInfo = () => {
    setOpenRequestInfoModal(true);
  };

  const closeModals = () => {
    setOpenRequestInfoModal(false);
  };

  // console.log(pageData[1]?.nav_menu_heading);
  return (
    <>
      {openRequestInfoModal && <RequestInfoModal closeModals={closeModals} />}
      <div
        style={{ backgroundColor: bgColor }}
        className="bayan-header on-desktop"
      >
        <div className="container">
          <div className="inner-header desktop-header">
            <div className="nav">
              <div className="logo-area">
                <Link href="/" as="/">
                  <a>
                    {/* <Image
											src="/icons/bayan-only.svg"
											alt="bayan-logo"
											width={200}
											height={64}
											loading="lazy"
										/> */}
                    {/* <LazyLoadImage src="https://bayan.s3-us-west-2.amazonaws.com/static-content/video.png" alt="bayan-webinar" /> */}
                    <img
                      src="/icons/bayan-only.svg"
                      alt="bayan-logo"
                      width="200"
                    />
                  </a>
                  {/* <a><img src="/icons/logo-in.png"></img> BAYAN</a> */}
                </Link>
              </div>
              <div
                className="nav-area"
                style={href.indexOf("eoy") > -1 ? {} : { margin: "10px 74px" }}
                ref={node}
              >
                <div className="w3-dropdown-click">
                  <button
                    className={`w3-btn w3-white w3-round ${dropDownOpen.programs}`}
                    onClick={() => toggleNavs("programs")}
                  >
                    {pageData[0]?.nav_menu_heading}
                    <i className="uniCode"></i>
                  </button>
                  <div
                    id="programs"
                    className={`w3-dropdown-content w3-bar-block w3-card ${dropDown.programs}`}
                  >
                    <div className="dropdown-area">
                      <div className="first-section col">
                        <div className="degree-program-heading">
                          <Link
                            href={
                              pageData
                                ? pageData[0]?.make_a_menu[0]?.heading_url
                                : ""
                            }
                            as={
                              pageData
                                ? pageData[0]?.make_a_menu[0]?.heading_url_as
                                : ""
                            }
                          >
                            {/*<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")} alt="Degree Programs">Degree Programs</a>*/}
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                              alt="Degree Programs"
                            >
                              {pageData
                                ? pageData[0]?.make_a_menu[0]?.heading
                                : ""}
                            </a>
                          </Link>
                        </div>
                        <div className="degree-program-sub-headings">
                          {pageData
                            ? pageData[0]?.make_a_menu[0]?.inner_menus.map(
                                (item, i) => {
                                  return (
                                    <Link
                                      key={i}
                                      href={item ? item?.label_url : ""}
                                      as={item ? item?.label_url_as : ""}
                                    >
                                      <a
                                        style={{ color: primaryColor }}
                                        className="degree-program-heading"
                                        onClick={() => toggleNavs("closeAll")}
                                      >
                                        {item?.label ?? "label"}
                                      </a>
                                    </Link>
                                  );
                                }
                              )
                            : ""}
                        </div>
                      </div>
                      <div className="second-section col">
                        <div className="session-program-heading">
                          <Link
                            href={
                              pageData
                                ? pageData[0]?.make_a_menu[1]?.heading_url
                                : ""
                            }
                            as={
                              pageData
                                ? pageData[0]?.make_a_menu[1]?.heading_url_as
                                : ""
                            }
                          >
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                              alt="Sessions"
                            >
                              {pageData
                                ? pageData[0]?.make_a_menu[1]?.heading
                                : ""}
                            </a>
                          </Link>
                        </div>
                        <div className="session-program-sub-headings">
                          {pageData
                            ? pageData[0]?.make_a_menu[1]?.inner_menus.map(
                                (item, i) => {
                                  return (
                                    <Link
                                      key={i}
                                      href={item ? item?.label_url : ""}
                                      as={item ? item?.label_url_as : ""}
                                    >
                                      <a
                                        style={{ color: primaryColor }}
                                        className="degree-program-heading"
                                        onClick={() => toggleNavs("closeAll")}
                                      >
                                        {item?.label ?? "label"}
                                      </a>
                                    </Link>
                                  );
                                }
                              )
                            : ""}
                        </div>
                      </div>
                      <div className="third-section col">
                        <div className="education-program-heading">
                          <Link
                            href={
                              pageData
                                ? pageData[0]?.make_a_menu[2]?.heading_url
                                : ""
                            }
                            as={
                              pageData
                                ? pageData[0]?.make_a_menu[2]?.heading_url_as
                                : ""
                            }
                          >
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                            >
                              {pageData
                                ? pageData[0]?.make_a_menu[2]?.heading
                                : ""}
                            </a>
                          </Link>
                        </div>
                        <div className="education-program-sub-headings">
                          {pageData
                            ? pageData[0]?.make_a_menu[2]?.inner_menus.map(
                                (item, i) => {
                                  return (
                                    <Link
                                      key={i}
                                      href={item ? item?.label_url : ""}
                                      as={item ? item?.label_url_as : ""}
                                    >
                                      <a
                                        style={{ color: primaryColor }}
                                        className="degree-program-heading"
                                        onClick={() => toggleNavs("closeAll")}
                                      >
                                        {item?.label ?? "label"}
                                      </a>
                                    </Link>
                                  );
                                }
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<button className={`w3-btn w3-white w3-round ${dropDownOpen.programs}`} onClick={() => toggleNavs("programs")}>PROGRAMS<i className="uniCode"></i></button>*/}
                  {/*<div id="programs" className={`w3-dropdown-content w3-bar-block w3-card ${dropDown.programs}`}>*/}
                  {/*	<div className="dropdown-area">*/}
                  {/*		<div className="first-section col">*/}
                  {/*			<div className="degree-program-heading">*/}
                  {/*				<Link href="/degree-programs" as="/degree-programs">*/}
                  {/*					/!*<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")} alt="Degree Programs">Degree Programs</a>*!/*/}
                  {/*					<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")} alt="Degree Programs">{pageData?.make_a_menu[0]?.heading ?? ""}</a>*/}
                  {/*				</Link>*/}
                  {/*			</div>*/}
                  {/*			<div className="degree-program-sub-headings">*/}
                  {/*				<Link href="/degree-programs/islamic-studies" as="/islamic-studies">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Studies</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/degree-programs/islamic-leadership" as="/islamic-leadership">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Leadership</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/degree-programs/islamic-theology" as="/islamic-theology">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Theology</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/degree-programs/islamic-education" as="/islamic-education">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Education</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/degree-programs/islamic-chaplaincy" as="/islamic-chaplaincy">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MDiv in Islamic Chaplaincy</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/courses" as="/courses">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Course Descriptions</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/chaplaincy-certificate" as="/chaplaincy-certificate">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Chaplaincy Certificates</a>*/}
                  {/*				</Link>*/}
                  {/*			</div>*/}
                  {/*		</div>*/}
                  {/*		<div className="second-section col">*/}
                  {/*			<div className="session-program-heading">*/}
                  {/*				<Link href="/sessions" as="/sessions">*/}
                  {/*					<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")} alt="Sessions">Sessions</a>*/}
                  {/*				</Link>*/}
                  {/*			</div>*/}
                  {/*			<div className="session-program-sub-headings">*/}
                  {/*				<Link href="/sessions" as="/sessions">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Spring 2021</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/sessions" as="/sessions">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Summer 2021</a>*/}
                  {/*				</Link>*/}
                  {/*			</div>*/}
                  {/*		</div>*/}
                  {/*		<div className="third-section col">*/}
                  {/*			<div className="education-program-heading">*/}
                  {/*				<Link href="/continuing-education" as="/continuing-education">*/}
                  {/*					<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")}>Continuing Education</a>*/}
                  {/*				</Link>*/}
                  {/*			</div>*/}
                  {/*			<div className="education-program-sub-headings">*/}
                  {/*				<Link href="/arabic" as="/arabic">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Intensive Arabic Online</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/continuing-education#earn-a-certificate" as="/continuing-education#earn-a-certificate">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Graduate Certificates</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/library" as="/library">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Self-Paced Courses</a>*/}
                  {/*				</Link>*/}
                  {/*				<Link href="/seminar" as="/seminar">*/}
                  {/*					<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Online Classical Texts Seminar</a>*/}
                  {/*				</Link>*/}
                  {/*			</div>*/}
                  {/*		</div>*/}
                  {/*	</div>*/}
                  {/*</div>*/}
                </div>
                {/*<div className="w3-dropdown-click">*/}
                {/*	<button className={`w3-btn w3-white w3-round ${dropDownOpen.programs}`} onClick={() => toggleNavs("programs")}>*/}
                {/*		/!*PROGRAMS *!/*/}
                {/*		{pageData?.nav_menu_heading}*/}
                {/*		<i className="uniCode"></i>*/}
                {/*	</button>*/}
                {/*	<div id="programs" className={`w3-dropdown-content w3-bar-block w3-card ${dropDown.programs}`}>*/}
                {/*		<div className="dropdown-area">*/}
                {/*			<div className="first-section col">*/}
                {/*				<div className="degree-program-heading">*/}
                {/*					<Link href="/degree-programs" as="/degree-programs">*/}
                {/*						/!*<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")} alt="Degree Programs">Degree Programs</a>*!/*/}
                {/*						<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")} alt="Degree Programs">{pageData?.make_a_menu[0]?.heading ?? ""}</a>*/}
                {/*					</Link>*/}
                {/*				</div>*/}
                {/*				<div className="degree-program-sub-headings">*/}
                {/*					<Link href="/degree-programs/islamic-studies" as="/islamic-studies">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Studies</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/degree-programs/islamic-leadership" as="/islamic-leadership">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Leadership</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/degree-programs/islamic-theology" as="/islamic-theology">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Theology</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/degree-programs/islamic-education" as="/islamic-education">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MA in Islamic Education</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/degree-programs/islamic-chaplaincy" as="/islamic-chaplaincy">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>MDiv in Islamic Chaplaincy</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/courses" as="/courses">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Course Descriptions</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/chaplaincy-certificate" as="/chaplaincy-certificate">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Chaplaincy Certificates</a>*/}
                {/*					</Link>*/}
                {/*				</div>*/}
                {/*			</div>*/}
                {/*			<div className="second-section col">*/}
                {/*				<div className="session-program-heading">*/}
                {/*					<Link href="/sessions" as="/sessions">*/}
                {/*						<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")} alt="Sessions">Sessions</a>*/}
                {/*					</Link>*/}
                {/*				</div>*/}
                {/*				<div className="session-program-sub-headings">*/}
                {/*					<Link href="/sessions" as="/sessions">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Spring 2021</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/sessions" as="/sessions">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Summer 2021</a>*/}
                {/*					</Link>*/}
                {/*				</div>*/}
                {/*			</div>*/}
                {/*			<div className="third-section col">*/}
                {/*				<div className="education-program-heading">*/}
                {/*					<Link href="/continuing-education" as="/continuing-education">*/}
                {/*						<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")}>Continuing Education</a>*/}
                {/*					</Link>*/}
                {/*				</div>*/}
                {/*				<div className="education-program-sub-headings">*/}
                {/*					<Link href="/arabic" as="/arabic">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Intensive Arabic Online</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/continuing-education#earn-a-certificate" as="/continuing-education#earn-a-certificate">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Graduate Certificates</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/library" as="/library">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Self-Paced Courses</a>*/}
                {/*					</Link>*/}
                {/*					<Link href="/seminar" as="/seminar">*/}
                {/*						<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Online Classical Texts Seminar</a>*/}
                {/*					</Link>*/}
                {/*				</div>*/}
                {/*			</div>*/}
                {/*		</div>*/}
                {/*	</div>*/}
                {/*</div>*/}
                <div className="w3-dropdown-click">
                  <button
                    className={`w3-btn w3-white w3-round ${dropDownOpen.admissions}`}
                    onClick={() => toggleNavs("admissions")}
                  >
                    ADMISSIONS <i className="uniCode"></i>
                  </button>
                  <div
                    id="admissions"
                    className={`w3-dropdown-content w3-bar-block w3-card ${dropDown.admissions}`}
                  >
                    <div className="dropdown-area">
                      <div className="first-section col">
                        <div className="degree-program-heading">
                          <Link href="/admissions" as="/admissions">
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Graduate School
                            </a>
                          </Link>
                        </div>
                        <div className="degree-program-sub-headings">
                          <Link href="/admissions" as="/admissions">
                            <a
                              alt="Apply Now"
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Apply Now
                            </a>
                          </Link>
                          <Link
                            href="/admissions/information"
                            as="/admissions/information"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Information Sessions
                            </a>
                          </Link>
                          <Link
                            href="/admissions/tuition-fees"
                            as="/admissions/tuition-fees"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Tuition & Fees
                            </a>
                          </Link>
                          <Link
                            href="/admissions/financial-aid"
                            as="/admissions/financial-aid"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Financial Aid & Scholarships
                            </a>
                          </Link>
                          <Link
                            href="/admissions"
                            as="/admissions/scholarship-grant"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Certificate in Islamic Chaplaincy
                            </a>
                          </Link>
                        </div>
                      </div>
                      {/* <div className="second-section col">
												<div className="session-program-heading">
													<Link href="/admissions/affordability" as="/admissions/affordability">
														<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")}>Affordability</a>
													</Link>
												</div>
												<div className="degree-program-sub-headings">
													<Link href="/admissions/tuition-fees" as="/admissions/tuition-fees">
														<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Tuition & Fees</a>
													</Link>
													<Link href="/admissions/financial-aid" as="/admissions/financial-aid">
														<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Financial Aid & Scholarships</a>
													</Link>
												</div>
											</div> */}
                      <div className="third-section col">
                        <div className="education-program-heading">
                          <h3 style={{ textAlign: "left" }}>
                            Get Started Now!
                          </h3>
                        </div>
                        <div className="education-program-sub-headings admission-buttons-align">
                          <Link passHref href="/apply">
                            <a
                              target="_blank"
                              style={{ color: primaryColor }}
                              className="degree-program-button"
                              onClick={() => toggleNavs("closeAll")}
                              alt="Apply Now"
                            >
                              Apply Now
                            </a>
                          </Link>
                          {userId == null ? (
                            <a
                              style={{ backgroundColor: primaryColor }}
                              className="degree-program-button btn-request-info"
                              onClick={handleRequestInfo}
                            >
                              Request Info
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w3-dropdown-click">
                  <button
                    className={`w3-btn w3-white w3-round ${dropDownOpen.community}`}
                    onClick={() => toggleNavs("community")}
                  >
                    COMMUNITY <i className="uniCode"></i>
                  </button>
                  <div
                    id="community"
                    className={`w3-dropdown-content w3-bar-block w3-card ${dropDown.community}`}
                  >
                    <div className="dropdown-area">
                      <div className="first-section col">
                        <div className="degree-program-heading">
                          <Link
                            href="/student-resources"
                            as="/student-resources"
                          >
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Student Resources
                            </a>
                          </Link>
                        </div>
                        <div className="degree-program-sub-headings">
                          <a
                            target="_blank"
                            href="https://mycts.ctschicago.edu/"
                            style={{ color: primaryColor }}
                            className="degree-program-heading"
                            onClick={() => toggleNavs("closeAll")}
                          >
                            Student Portal
                          </a>
                          <Link
                            href="/student-resources"
                            as="/student-resources"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Canvas LMS
                            </a>
                          </Link>
                          <Link
                            href="/student-resources"
                            as="/student-resources"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Library
                            </a>
                          </Link>
                        </div>
                      </div>
                      {/* <div className="second-section col">
												<div className="session-program-heading">
													<Link href="/register" as="/register">
														<a style={{ color: primaryColor }} onClick={() => toggleNavs("closeAll")}>Connect with</a>
													</Link>
												</div>
												<div className="degree-program-sub-headings">
													<Link href="/register" as="/register" passHref>
														<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Students</a>
													</Link>
													<Link href="/register" as="/register">
														<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Alumni</a>
													</Link>
													<Link href="/register" as="/register">
														<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Faculty</a>
													</Link>
													<Link href="/register" as="/register">
														<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Leadership</a>
													</Link>
													<Link href="/register" as="/register">
														<a style={{ color: primaryColor }} className="degree-program-heading" onClick={() => toggleNavs("closeAll")}>Staff</a>
													</Link>
												</div>
											</div> */}
                      {/* <div className="third-section col">
                        <div className="education-program-heading">
                          <Link href="/community" as="/community">
                            <a style={{ color: primaryColor }}>
                              Join Our Community
                            </a>
                          </Link>
                        </div>
                        <div className="education-program-sub-headings">
                          <Link href="/download-app" as="/download-app">
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                            >
                              Download the app
                            </a>
                          </Link>
                          <Link href="/" as="/">
														<a style={{ color: primaryColor }} className="degree-program-heading">Employment</a>
													</Link>
													<Link href="/" as="/">
														<a style={{ color: primaryColor }} className="degree-program-heading">Join our endowment</a>
													</Link>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="w3-dropdown-click">
                  <button
                    className={`w3-btn w3-white w3-round ${dropDownOpen.aboutByana}`}
                    onClick={() => toggleNavs("aboutByana")}
                  >
                    ABOUT BAYAN <i className="uniCode"></i>
                  </button>
                  <div
                    id="aboutByana"
                    className={`w3-dropdown-content w3-bar-block w3-card ${dropDown.aboutByana}`}
                  >
                    <div className="dropdown-area">
                      <div className="first-section col">
                        <div className="degree-program-heading">
                          <Link href="/about" as="/about" passHref={true}>
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                            >
                              About Bayan
                            </a>
                          </Link>
                        </div>
                        <div className="degree-program-sub-headings">
                          {/* <Link href="/about#mission-and-vision" as="/about#mission-and-vision" passHref> */}
                          <a
                            href="/about#mission-and-vision"
                            style={{ color: primaryColor }}
                            className="degree-program-heading"
                            onClick={() => toggleNavs("closeAll")}
                          >
                            Mission & Vision
                          </a>
                          {/* </Link> */}
                          <Link
                            href="/about#accreditation"
                            as="/about#accreditation"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Accreditation
                            </a>
                          </Link>
                          <Link
                            href="/about#partner-schools"
                            as="/about#partner-schools"
                          >
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Partner Institutions
                            </a>
                          </Link>
                          <Link href="/about/philosophy" as="/about/philosophy">
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Philosophy
                            </a>
                          </Link>
                          <Link href="/leadership" as="/leadership">
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Leadership
                            </a>
                          </Link>
                          {/* <Link href="/faculty" as="/faculty">
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Faculty
                            </a>
                          </Link> */}
                          <Link href="/staff" as="/staff">
                            <a
                              style={{ color: primaryColor }}
                              className="degree-program-heading"
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Staff
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div className="second-section col">
                        <div className="session-program-heading">
                          <Link href="/news-events" as="/news-events">
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                            >
                              News & Events
                            </a>
                          </Link>
                        </div>
                        <div className="session-program-heading">
                          <a
                            style={{
                              color: primaryColor,
                              cursor: "auto",
                              textDecoration: "none",
                            }}
                          >
                            Student Body Profiles
                          </a>
                        </div>
                        <div className="degree-program-sub-headings">
                          {studentBodyProfiles?.map((data) => {
                            return (
                              <Link
                                href={`/about/${data?.slug}`}
                                as={`/about/${data?.slug}`}
                              >
                                <a
                                  style={{ color: primaryColor }}
                                  className="degree-program-heading"
                                >
                                  {data?.title}
                                </a>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                      <div className="third-section col">
                        <div className="education-program-heading">
                          <Link href="/contact" as="/contact">
                            <a
                              style={{ color: primaryColor }}
                              onClick={() => toggleNavs("closeAll")}
                            >
                              Contact Us
                            </a>
                          </Link>
                        </div>
                        <div className="education-program-sub-headings">
                          <div className="top-edu">
                            <Link href={socialLinks.facebook}>
                              <a target="_blank">
                                <img
                                  src={`${process.env.IMAGES_BASE_URL}/static-content/fbook.png.webp`}
                                  alt="Facebook Icon"
                                />
                              </a>
                            </Link>
                            <Link href={socialLinks.twitter}>
                              <a target="_blank">
                                <img
                                  src={`${process.env.IMAGES_BASE_URL}/static-content/twitter.png.webp`}
                                  alt="Twitter Icon"
                                />
                              </a>
                            </Link>
                            <Link href={socialLinks.youtube}>
                              <a target="_blank">
                                <img
                                  src={`${process.env.IMAGES_BASE_URL}/static-content/youtube.png.webp`}
                                  alt="Youtube Icon"
                                />
                              </a>
                            </Link>
                            <Link href={socialLinks.instagram}>
                              <a target="_blank">
                                <img
                                  src={`${process.env.IMAGES_BASE_URL}/static-content/instagram.png.webp`}
                                  alt="Instagram Icon"
                                />
                              </a>
                            </Link>
                            <Link href={socialLinks.linkedin}>
                              <a target="_blank">
                                <img
                                  src={`${process.env.IMAGES_BASE_URL}/static-content/linkediniconpng.png`}
                                  alt="LinkedIn Icon"
                                />
                              </a>
                            </Link>
                          </div>
                          <div className="bottom-edu">
                            <a href="tel:714 862-2926">
                              <img
                                src={`${process.env.IMAGES_BASE_URL}/static-content/phone.png.webp`}
                                alt="phone"
                              />
                              <span>(714) 862-2926</span>
                            </a>
                            <a href="mailto:support@bayanonline.org">
                              <img
                                src={`${process.env.IMAGES_BASE_URL}/static-content/email.png.webp`}
                                alt="mail"
                              />
                              <span>support@bayanonline.org</span>
                            </a>
                            <div>
                              <img
                                src={`${process.env.IMAGES_BASE_URL}/static-content/map.png.webp`}
                                alt="location"
                              />
                              <span>1407 E 60th St, Chicago, IL 60637</span>
                            </div>
                            <div className="contact-section">
                              <img
                                src={`/icons/noun_mailbox@3x.png`}
                                alt="location"
                                width="20"
                              />
                              <span>
                                2854 N. Santiago Blvd., Suite 201, Orange, CA
                                92867
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w3-dropdown-click">
                  <a
                    href="https://direct.bayanclaremont.org/courses"
                    className={`w3-btn w3-white w3-round`}
                    target="_blank"
                  >
                    ON-DEMAND
                  </a>
                </div>
                <div className="w3-dropdown-click">
                  <a
                    href="https://bayantours.org"
                    className={`w3-btn w3-white w3-round`}
                    target="_blank"
                  >
                    TOURS
                  </a>
                </div>
                <div className="w3-dropdown-click">
                  <a
                    href="/shop"
                    className={`w3-btn w3-white w3-round`}
                    target="_blank"
                  >
                    SHOP
                  </a>
                </div>

                {href.indexOf("eoy") > -1 ? (
                  <div className="w3-dropdown-click bayan-donation-btn-head">
                    <a href="#XDCTTDMQ"> FUNDRAISE </a>
                  </div>
                ) : (
                  <></>
                )}
                <div className="w3-dropdown-click bayan-donation-btn-head">
                  {/* <div className="w3-dropdown-click bayan-donation-btn-head"> */}
                  {/* <button className={`bayan-donate-btn`}> */}
                  {/* <Link href="/support" as="/support"><a style={{ color: primaryColor }} >DONATE</a></Link> */}

                  {/* <a style={{ display: 'none' }} href="#FUNPXTDUDLU"> DONATE </a> */}
                  {/* </button> */}

                  {/* <button className={`bayan-donate-btn1`}> */}
                  {/* <Link href="/support" as="/support"><a style={{ color: primaryColor }} >DONATE</a></Link> */}
                  <a className={`bayan-donate-btn`} href="#FUNPXTDUDLU">
                    {" "}
                    DONATE{" "}
                  </a>
                  <Link passHref href="/apply">
                    <a className={`bayan-apply-btn`}> APPLY NOW </a>
                  </Link>
                  {/* </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
